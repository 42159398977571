<template>
    <div class="mt-2">
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table
            ref="table"
            striped
            responsive
            :filter="filter"
            :items="bannersForTable"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter-included-fields="['name', 'sourceName', 'brandName']"
            show-empty
            empty-text="Aucune bannières trouvée"
            empty-filtered-text="Aucune bannières correspondant au(x) filtre(s) trouvée"
            @filtered="onTableFiltered"
        >
            <template #cell(Actions)="data">
                <banner-action
                    :key="currentPage + filter + data.item.code"
                    :banner-data="data.item" :brands="brands"
                    :patterns="patterns"
                    @on-refresh="$emit('on-refresh')"></banner-action>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import BannerAction from './banner-action.vue';
    export default {
        components: {
            BannerAction,
        },
        props: {
            banners: {
                type: Array,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            patterns: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [{ key: 'name', label: 'Nom du fichier' }, { key: 'sourceName', label: 'Source' }, { key: 'brandName', label: 'Marque' }, 'Actions'],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: 0,
            };
        },
        computed: {
            bannersForTable() {
                let data = this.banners.map((banner) => {
                    return {
                        ...banner,
                        brandName: banner.brand.label,
                        sourceName: banner.pattern.source
                        // sourceName: banner.pattern.name,
                    };
                });
                if (this.filter === '') this.setRows(data);
                return data;
            },
        },
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>
