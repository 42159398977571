<template>
    <b-tabs v-model="tabIndex">
        <b-tab title="Sources">
            <source-tab :patterns="patterns" @on-refresh="fetchPatterns"></source-tab>
        </b-tab>
        <b-tab title="Bannières">
            <banner-tab :brands="brands" :patterns="patterns" :banners="banners" @on-refresh="fetchBanners"></banner-tab>
        </b-tab>
    </b-tabs>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import BannerTab from './Banner/index.vue';
    import SourceTab from './Source/index.vue';
    export default {
        components: {
            BannerTab,
            SourceTab,
        },
        data() {
            return {
                brands: [],
                patterns: [],
                banners: [],
                tabIndex: 1,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions({
                getBannerBrands: 'app/getBannerBrands',
                getBannerPatterns: 'app/getBannerPatterns',
                getBanners: 'app/getBanners',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBanners(), this.fetchBrands(), this.fetchPatterns()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBanners() {
                return this.getBanners().then((res) => {
                    this.$set(this, 'banners', res.data);
                });
            },
            fetchBrands() {
                return this.getBannerBrands().then((res) => {
                    this.$set(this, 'brands', res.data);
                });
            },
            fetchPatterns() {
                return this.getBannerPatterns().then((res) => {
                    this.$set(this, 'patterns', res.data);
                });
            },
        },
    };
</script>

<style></style>
