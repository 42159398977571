<template>
    <generic-confirm-modal ref="modal" title="Prévisualisation" :ok-only="true" ok-title="Fermer" @on-accept="close">
        <div class="text-center">
            <iframe width="305" height="255" :src="url"></iframe>
        </div>
    </generic-confirm-modal>
</template>

<script>
    export default {
        props: {
            bannerData: {
                type: Object,
                required: true,
            },
        },
        computed: {
            url() {
                return `${this.$store.state.app.apiBanner}/display/${this.bannerData.brand.brand}/${this.bannerData.code}/300x250/`;
            },
        },
        methods: {
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
