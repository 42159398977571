<template>
    <generic-confirm-modal ref="modal" title="Modification de bannière" cancel-title="Annuler" ok-title="Modifier" @on-accept="updateBanner" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                    <b-form-select
                        id="input-select-brand"
                        v-model="selectedBrand"
                        :options="brands"
                        text-field="label"
                        value-field="id"
                        :state="ruleSelectedBrand || !showFormError ? null : false"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="ruleSelectedBrand || !showFormError ? null : false"> Une marque doit ếtre sélectionnée </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Sélectionner une source" label-for="input-select-source">
                    <b-form-select
                        id="input-select-source"
                        v-model="selectedPattern"
                        :options="patterns"
                        text-field="source"
                        value-field="id"
                        :state="ruleSelectedPattern || !showFormError ? null : false"
                    ></b-form-select>
                    <b-form-invalid-feedback :state="ruleSelectedPattern || !showFormError ? null : false"> Une source doit ếtre sélectionnée </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Nom du fichier" label-for="input-file-name">
                    <b-input id="input-file-name" v-model="fileName" :state="ruleFileName || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleFileName || !showFormError ? null : false"> Ce champ est requis </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            brands: {
                type: Array,
                required: true,
            },
            patterns: {
                type: Array,
                required: true,
            },
            bannerData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                selectedBrand: '',
                selectedPattern: '',
                fileName: '',
                showFormError: false,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleSelectedBrand() {
                return this.selectedBrand !== '';
            },
            ruleSelectedPattern() {
                return this.selectedPattern !== '';
            },
            ruleFileName() {
                return this.fileName !== '';
            },
            validateForm() {
                return [this.ruleSelectedBrand, this.ruleSelectedPattern, this.ruleFileName].every((val) => val === true);
            },
        },
        mounted() {
            this.selectedBrand = this.bannerData.brandId;
            this.selectedPattern = this.bannerData.patternId;
            this.fileName = this.bannerData.name;
        },
        methods: {
            updateBanner() {
                if (!this.validateForm) return (this.showFormError = true);
                var data = {
                    code: this.bannerData.code,
                    name: this.fileName,
                    brandId: this.selectedBrand,
                    patternId: this.selectedPattern,
                };

                this.loading = true;

                this.$store
                    .dispatch('app/updateBanner', data)
                    .then((res) => {
                        this.$toast(this.successToast('La bannière a été modifiée avec succès'));
                        this.closeModal();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la modificatio de la bannière'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            open() {
                this.$refs.modal.open();
            },
            closeModal() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
