<template>
    <b-card>
        <source-form @on-refresh="$emit('on-refresh')"></source-form>
        <source-table :patterns="patterns" @on-refresh="$emit('on-refresh')"></source-table>
    </b-card>
</template>

<script>
    import SourceForm from './add-source-form.vue';
    import SourceTable from './table.vue';
    export default {
        components: {
            SourceForm,
            SourceTable,
        },
        props: {
            patterns: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style></style>
