<template>
    <div>
        <b-overlay :show="loading">
            <h3>Ajout d'une source</h3>
            <b-form @submit.stop.prevent>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Source" label-for="input-source">
                            <b-input id="input-source" v-model="sourceName" :state="ruleSourceName || !showFormError ? null : false"></b-input>
                            <b-form-invalid-feedback :state="ruleSourceName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col v-for="(lineValue, lineLabel) in lines" :key="lineLabel" cols="6">
                        <line-input :label="lineLabel" :value="lineValue" :showFormError="showFormError" @on-update-line="updateLine" @on-remove-line="removeLine"></line-input>
                    </b-col>

                    <b-col cols="6"> </b-col>
                </b-row>
                <b-btn variant="primary" @click="addNewLine">Ajouter ligne</b-btn>
                <b-btn variant="success" class="d-flex ml-auto" @click="confirmSourceUpload">Créer source</b-btn>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import LineManager from '@/partials/lines-management';
    import LineInput from './line-input.vue';
    export default {
        components: {
            LineInput,
        },
        props: {},
        data() {
            return {
                sourceName: '',
                lines: {},
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleSourceName() {
                return this.sourceName !== '';
            },
            formValid() {
                let rules = Object.values(this.lines).map((val) => this.checkLineRegex(val));
                rules.push(this.ruleSourceName);
                return rules.every((val) => val === true);
            },
        },
        mounted() {
            this.resetForm();
        },
        methods: {
            checkLineRegex(expression) {
                try {
                    new RegExp(expression);
                    return true;
                } catch (e) {
                    return false;
                }
            },
            updateLine(label, value) {
                this.$set(this.lines, label, value);
            },
            updateLines() {
                this.$set(this, 'lines', {});
                this.$set(this, 'lines', this.lineManager.lines);
            },
            addSource() {
                this.loading = true;
                let data = {
                    name: this.sourceName,
                    source: this.sourceName,
                    details: {
                        encoding: 'base64',
                        ...this.lines,
                    },
                };

                this.$store
                    .dispatch('app/createSource', data)
                    .then((res) => {
                        this.$toast(this.successToast('La source à été ajouté avec succès'));
                        this.$emit('on-refresh');
                        this.resetForm();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de l'ajout de la source"));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            addNewLine() {
                this.lineManager.addNewLine();
                this.updateLines();
            },
            removeLine(label) {
                this.lineManager.removeLine(label);
                this.updateLines();
            },
            resetForm() {
                this.sourceName = '';
                this.lineManager = new LineManager({ line1: '' });
                this.updateLines();
            },

            confirmSourceUpload() {
                if (!this.formValid) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter la source ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.addSource();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
        },
    };
</script>

<style></style>
