<template>
    <generic-confirm-modal ref="modal" title="Modification de source" cancel-title="Annuler" ok-title="Modifier" ok-variant="success" @on-accept="updateSource" @on-cancel="closeModal">
        <b-overlay :show="loading">
            <b-form @submit.stop.prevent>
                <b-form-group label="source" label-for="input-source-name">
                    <b-input id="input-source-name" v-model="sourceName" :state="ruleSourceName || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleSourceName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <template v-for="(lineValue, lineLabel) in lines">
                    <line-input :key="lineLabel + pasBien" :label="lineLabel" :value="lineValue" :showFormError="showFormError" @on-update-line="updateLine" @on-remove-line="removeLine"></line-input>
                </template>
            </b-form>
            <b-btn variant="primary" @click="addNewLine">Ajouter ligne</b-btn>
        </b-overlay>
    </generic-confirm-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import LineManager from '@/partials/lines-management';
    import LineInput from '../line-input.vue';
    export default {
        components: {
            LineInput,
        },
        props: {
            sourceData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                showFormError: false,
                sourceName: '',
                lines: {},
                lineManager: null,
                pasBien: 0,
                // technique pas tres propre mais qui marche qui permet de forcer le re-render d'un element de dom
                // utiliser pour pallier au probleme de réactivité
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleSourceName() {
                return this.sourceName !== '';
            },
            formValid() {
                let rules = Object.values(this.lines).map((val) => this.checkLineRegex(val));
                rules.push(this.ruleSourceName);
                return rules.every((val) => val === true);
            },
        },
        mounted() {
            this.sourceName = this.sourceData.source;
            this.lineManager = new LineManager(this.sourceData.details);
            this.updateLines();
        },
        methods: {
            updateLine(label, value) {
                this.$set(this.lines, label, value);
            },
            updateLines() {
                this.$set(this, 'lines', {});
                this.$set(this, 'lines', this.lineManager.lines);
            },
            checkLineRegex(expression) {
                try {
                    new RegExp(expression);
                    return true;
                } catch (e) {
                    return false;
                }
            },
            updateSource() {
                if (!this.formValid) return (this.showFormError = true);

                this.loading = true;

                let data = {
                    code: this.sourceData.code,
                    source: this.sourceName,
                    details: {
                        encoding: 'base64',
                        ...this.lines,
                    },
                };

                this.$store
                    .dispatch('app/updateSource', data)
                    .then((res) => {
                        this.$toast(this.successToast('La source a été modifiée avec succès'));
                        this.$emit('on-refresh');
                        this.$refs.modal.close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Error lors de la modification de la source'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            closeModal() {
                this.$refs.modal.close();
            },
            open() {
                this.$refs.modal.open();
            },
            addNewLine() {
                this.lineManager.addNewLine();
                this.updateLines();
            },
            removeLine(label) {
                this.lineManager.removeLine(label);
                this.updateLines();
                this.pasBien++;
            },
        },
    };
</script>

<style></style>
