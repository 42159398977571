<template>
    <generic-confirm-modal ref="modal" title="Liste des résolutions disponibles" ok-title="Fermer" :ok-only="true" size="lg" @on-accept="close">
        <b-input-group class="mb-1 w-50 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="bannerData.childBanners" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered">
            <template #cell(path)="data">
                <url-cell :path="data.value"></url-cell>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>*
    </generic-confirm-modal>
</template>

<script>
    import UrlCell from './list-resolution-url-cell.vue';
    export default {
        components: {
            UrlCell,
        },
        props: {
            bannerData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                filter: '',
                perPage: 10,
                currentPage: 1,
                rows: 0,
                fields: [
                    { key: 'resolution', label: 'Résolution' },
                    { key: 'path', label: 'URL' },
                ],
            };
        },
        methods: {
            onTableFiltered(list) {
                this.rows = list.length;
            },
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },
        },
    };
</script>

<style></style>
