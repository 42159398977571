<template>
    <b-form-group :label="'Regexp ' + label" :label-for="'input-' + label">
        <b-input-group>
            <b-input :id="'input-' + label" v-model="lineValue" :state="ruleRegex || !showFormError ? null : false" @input="$emit('on-update-line', label, lineValue)"></b-input>
            <b-input-group-append>
                <b-button variant="outline-warning" @click="removeLine">
                    <feather-icon icon="MinusCircleIcon"></feather-icon>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback :state="ruleRegex || !showFormError ? null : false"> Ce champ doit être renseigné avec une regex <b>VALIDE</b> </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                required: true,
            },
            showFormError: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                lineValue: '',
            };
        },
        computed: {
            ruleRegex() {
                try {
                    new RegExp(this.lineValue);
                    return true;
                } catch (e) {
                    return false;
                }
            },
        },
        watch: {
            value: {
                handler(val) {
                    this.lineValue = val;
                },
                immediate: true,
            },
        },
        methods: {
            removeLine() {
                this.$emit('on-remove-line', this.label);
            },
        },
    };
</script>

<style></style>
