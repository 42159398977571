<template>
    <div class="mt-2">
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>
        <b-table ref="table" striped responsive :filter="filter" :items="sourceForTable" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered">
            <template #cell(Actions)="data">
                <source-action
                    :key="currentPage + filter + data.item.code"
                    :source-data="data.item"
                    :patterns="patterns"
                    @on-refresh="$emit('on-refresh')"></source-action>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" align="right" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import SourceAction from './source-action.vue';
    export default {
        components: {
            SourceAction,
        },
        props: {
            patterns: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                fields: [{ key: 'source', label: 'Source' }, 'Actions'],
                perPage: 10,
                currentPage: 1,
                filter: '',
                rows: this.patterns.length,
            };
        },
        computed: {
            sourceForTable() {
                if (this.filter === '') this.setRows(this.patterns);
                return this.patterns;
            },
        },
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>
