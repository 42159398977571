<template>
    <div :id="'resolution-popover' + path">
        <a :href="url + '/index.html'" target="_blank">{{ url }}</a>
    </div>
</template>

<script>
    export default {
        props: {
            path: {
                type: String,
                required: true,
            },
        },
        computed: {
            url() {
                let url = this.path.match(/(\w+\/[a-zA-Z0-9]+\/\d+x\d+)/);
                if (url) {
                    return `${this.$store.state.app.apiBanner}/display/${url[0]}`;
                }
                return 'Url non trouvé';
            },
        },
    };
</script>

<style></style>
