export default class LinesManagement {
    constructor(defaultLines = {}) {
        let entries = Object.entries(defaultLines);
        entries = entries.filter((entry) => entry[0].startsWith('line'));
        this.lines = {};
        for (let entry of entries) {
            this.addLine(entry[0], entry[1]);
        }
    }

    addNewLine() {
        let index = Object.keys(this.lines).length;
        this.addLine(`line${index + 1}`);
    }

    addLine(label, value = '') {
        this.lines[label] = value;
    }

    removeLine(label) {
        delete this.lines[label];
        this.checkContinuity();
    }

    checkContinuity() {
        // check que les numéro de ligne sont bien continue qu'on ce retrouve pas avec des un saut de la ligne 2 à 4 sans ligne 3 car celle-ci aurait été supprimé. Si on ne souhaite pas remplir la lign 3 il suffit de laisser le champs vide
        let reOrder = false;
        let count = 1;
        for (let line in this.lines) {
            let index = line.match(/(\d+)/)[0];
            if (count !== parseInt(index)) {
                reOrder = true;
                break;
            }
            count++;
        }
        if (reOrder) {
            count = 1;
            let lines = {};
            for (let line in this.lines) {
                lines[`line${count}`] = this.lines[line];
                count++;
            }
            this.lines = lines;
        }
    }
}
