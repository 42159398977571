<template>
    <div>
        <b-btn variant="warning" class="p-1 mr-1" @click="onEdit">
            <feather-icon icon="Edit3Icon"></feather-icon>
        </b-btn>
        <b-btn variant="danger" class="p-1 mr-1" @click="onDelete">
            <feather-icon icon="TrashIcon"></feather-icon>
        </b-btn>
        <b-btn variant="primary" class="p-1 mr-1" @click="openPreviewModal">
            <feather-icon icon="EyeIcon"></feather-icon>
        </b-btn>
        <b-btn variant="primary" class="p-1" @click="openModalListResolutionAvailable">
            <feather-icon icon="MoreVerticalIcon"></feather-icon>
        </b-btn>
        <edit-banner-modal ref="modal-edit" :brands="brands" :patterns="patterns" :banner-data="bannerData"></edit-banner-modal>
        <preview-banner-modal ref="modal-preview" :banner-data="bannerData"></preview-banner-modal>
        <list-resolution-modal ref="modal-list-resolution" :banner-data="bannerData"></list-resolution-modal>
    </div>
</template>

<script>
    import EditBannerModal from './Modals/edit.vue';
    import PreviewBannerModal from './Modals/preview-banner.vue';
    import ListResolutionModal from './Modals/banner-available-resolution.vue';
    import { mapGetters } from 'vuex';
    export default {
        components: {
            EditBannerModal,
            PreviewBannerModal,
            ListResolutionModal,
        },
        props: {
            bannerData: {
                type: Object,
                required: true,
            },
            brands: {
                type: Array,
                required: true,
            },
            patterns: {
                type: Array,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        methods: {
            onEdit() {
                this.$refs['modal-edit'].open();
            },
            openPreviewModal() {
                this.$refs['modal-preview'].open();
            },
            openModalListResolutionAvailable() {
                this.$refs['modal-list-resolution'].open();
            },
            onDelete() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir supprimer la bannière ?', this.modalContext('danger'))
                    .then((confirmed) => {
                        if (confirmed) this.deleteBanner();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            deleteBanner() {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/deleteBanner', this.bannerData.code)
                    .then((res) => {
                        this.$toast(this.successToast('La bannière a été supprimée avec succès'));
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        this.$toast(this.errorToast('Erreur lors de la suppresion de la bannière'));
                        console.error(e);
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style></style>
